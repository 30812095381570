import { React, useState } from "react";
import styles from "./Navbar.module.css";
import Logo from "../../images/logo.png";
import { MdEmail, MdPhone } from "react-icons/md";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { FaWhatsappSquare } from "react-icons/fa";

const Navbar = ({ scrollToSection, sectionRefs }) => {
  const [nav, setNav] = useState(false);
  const mailtoLink = `mailto:contactus@Dattamsa.org?subject=Hello&body=This%20is%20a%20test%20email`;
  const phoneNumber = "9052247773"; // Replace with your number

  const handleClick = (event, sectionRef) => {
    event.preventDefault(); // Prevent any default behavior
    scrollToSection(sectionRef); // Scroll to the specific section
  };

  return (
    <header className={styles.navbar}>
      <div>
        <img src={Logo} alt="Logo" className={styles.logoImage} />
      </div>
      <nav>
        <ul
          className={
            nav ? [styles.menu, styles.active].join(" ") : [styles.menu]
          }
        >
          <li>
            <a href="/">Home</a>
          </li>

          <li onClick={(e) => handleClick(e, sectionRefs.aboutRef)}>
            <a href="/#">About</a>
          </li>
          <li onClick={(e) => handleClick(e, sectionRefs.servicesRef)}>
            <a href="/#">Services</a>
          </li>
          <li onClick={(e) => handleClick(e, sectionRefs.clientRef)}>
            <a href="/#">Clients</a>
          </li>
          <li onClick={(e) => handleClick(e, sectionRefs.contactRef)}>
            <a href="/#">Contact</a>
          </li>
          <li className={styles.last}>
            <a href={mailtoLink}>
              <MdEmail size={25} color="#2D1044" />
              <p>contactus@Dattamsa.org</p>
            </a>
          </li>
          <li className={styles.last}>
            <MdPhone size={25} color="#2D1044" /> <p>+91-9052247773</p>
          </li>
          <li className={styles.last}>
            <a
              href={`https://wa.me/${phoneNumber}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsappSquare size={30} color="#25D366" />
            </a>
          </li>
        </ul>
      </nav>
      <div onClick={() => setNav(!nav)} className={styles.mobile_btn}>
        {nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
      </div>
    </header>
  );
};

export default Navbar;
